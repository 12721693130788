<template>
  <div class="event-dates-list-container">
    <v-sheet elevation="2" tile class="pt-1 pb-1">
      <v-row class="mx-3 pb-2 my-1">
        <c-list-pagination
          class="header-pagination flex-grow-1"
          :pageSizes="[10, 25, 50, 100]"
          :list="viewModel"
        />
      </v-row>
    </v-sheet>
    <c-loader-status :loaders="{ 'no-initial-content': [viewModel.$load] }">
      <div
        ref="stickyHeader"
        class="date-heading-row"
        style="
          position: fixed;
          width: 100%;
          height: 48px;
          top: 48px;
          padding: 0 16px;
          display: none;
          z-index: 1;
          align-items: center;
          border-top-style: solid;
          border-bottom-style: solid;
          border-width: 1px;
          transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) top;
        "
      ></div>
      <v-table class="event-dates-list">
        <thead>
          <tr>
            <th
              class="text-left"
              width="150px"
              v-if="staticData.SHOW_IMAGE_ON_LIST_VIEW"
            >
              Event Image
            </th>
            <th class="text-left" width="200px">Event Name</th>
            <th class="text-left" width="">Description</th>
            <th class="text-left">Time</th>
            <th v-if="!staticData.isPublicUser || (staticData.isPublicUser && staticData.SHOW_EVENT_DURATION_IN_PUBLIC)" class="text-left">Duration</th>
            <th style="width: 10%" v-if="!staticData.isPublicUser">
              Hidden from Public Web after initial purchase
            </th>
            <th
              v-if="
                hasDescriptors && staticData.SHOW_STATUS_COLUMN_ON_LIST_VIEW
              "
            >
              Status
            </th>
            <th width="1%"></th>
          </tr>
        </thead>
        <tbody
          v-for="(eventDates, dateString) in eventDatesByDate"
          :key="dateString"
        >
          <tr :key="dateString" class="date-heading-row">
            <td class="" colspan="100">
              {{
                format(
                  eventDates[0].eventStartDateTimeLocal,
                  "EEEE, MMMM d, yyyy",
                )
              }}
            </td>
          </tr>
          <tr
            class="event-date-row"
            v-for="eventDate in eventDates"
            :key="eventDate.id"
          >
            <td
              data-label=""
              class="py-3 list-image-container"
              v-if="staticData.SHOW_IMAGE_ON_LIST_VIEW"
            >
              <div class="">
                <v-img
                  :src="eventDate.eventBannerImage"
                  class="event-date-image"
                />
              </div>
            </td>
            <td data-label="Event" class="text-body-2 py-3">
              <div class="font-weight-bold">
                {{ eventDate.eventName }}
              </div>
            </td>

            <td data-label="Description" class="text-caption py-1">
              <show-more
                v-if="
                  eventDate.eventShortDescription &&
                  eventDate.eventLongDescription &&
                  eventDate.eventShortDescription !=
                    eventDate.eventLongDescription
                "
              >
                <template #less>
                  <span
                    v-html="sanitizeHtml(eventDate.eventShortDescription.trim())"
                    style="white-space: pre-wrap"
                  ></span>
                </template>
                <template #more>
                  <span
                    v-html="sanitizeHtml(eventDate.eventLongDescription.trim())"
                    style="white-space: pre-wrap"
                  ></span>
                </template>
              </show-more>
              <span
                v-else
                v-html="
                sanitizeHtml(
                  (
                    eventDate.eventShortDescription ||
                    eventDate.eventLongDescription ||
                    ''
                  ).trim())
                "
                style="white-space: pre-wrap"
              ></span>
            </td>
            <td data-label="Start Time" class="text-no-wrap">
              {{ lightFormat(eventDate.eventStartDateTimeLocal, "h:mm a") }} {{eventDate.venueTimezone}}
            </td>
            <td v-if="!staticData.isPublicUser || (staticData.isPublicUser && staticData.SHOW_EVENT_DURATION_IN_PUBLIC)" data-label="Duration" class="text-no-wrap">
              {{ formatMinutes(eventDate.durationMinutes) }}
            </td>
            <td
              v-if="!staticData.isPublicUser"
              data-label="Hidden from public view"
              class="text-center text-no-wrap"
            >
              <v-icon size="small" v-if="eventDate.hiddenFromInitialPurchase"
                >fa fa-check
              </v-icon>
              <v-icon size="small" v-else>fa fa-x</v-icon>
            </td>
            <td
              data-label="Status"
              v-if="
                hasDescriptors && staticData.SHOW_STATUS_COLUMN_ON_LIST_VIEW
              "
            >
              <slot name="list-item-descriptors" v-bind:eventDate="eventDate" />
            </td>
            <td
              :data-label="action_label"
              class="action-label-container text-no-wrap"
            >
              <slot name="list-item-actions" v-bind:eventDate="eventDate" />
            </td>
          </tr>
        </tbody>
      </v-table>
    </c-loader-status>
  </div>
</template>

<script setup lang="ts">
import { EventDateDto } from "@/models.g";
import { EventDateDtoListViewModel } from "@/viewmodels.g";
import { format, lightFormat } from "date-fns";
import { formatMinutes, sanitizeHtml } from "@common/utils";
import { startOfDay } from "date-fns";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import ShowMore from "@common/components/show-more.vue";
import { getCurrentInstance } from "vue";
import useStaticData from "@common/composables/useStaticData";
const props = defineProps<{
  viewModel: EventDateDtoListViewModel;
  hasDescriptors?: boolean;
}>();
const vue = getCurrentInstance()?.proxy;
const stickyHeader = ref<HTMLElement>();
const staticData = useStaticData();

// Label for the actions container in mobile list view
const action_label = staticData.HAS_CUSTOM_BUTTON_TEXT ? "" : "Actions";

props.viewModel.$load();

const scrollParentRef = ref<HTMLElement | Document | null>(null);
const listener = (scrollParent: HTMLElement | Document) => {
  if (!stickyHeader.value) return;

  const appToolbar = scrollParent.querySelector(".v-app-bar");
  const toolbarHeight = appToolbar?.matches(".v-app-bar--hide-shadow")
    ? 0
    : (appToolbar?.clientHeight ?? 0);
  const dateHeadingHeight = stickyHeader.value.clientHeight;

  // Find the last date header that went off the top of the screen
  let useRow: Element | undefined;
  for (const row of Array.from(
    window.document.querySelectorAll("tr.date-heading-row"),
  )) {
    const rect = row.getBoundingClientRect();
    // Exclude y == 0 to avoid fake off-screen headers from default values
    if (rect.y != 0 && rect.y < toolbarHeight + dateHeadingHeight) {
      useRow = row;
    }
  }

  // Set the sticky header to that content.
  stickyHeader.value.innerHTML = useRow?.innerHTML ?? "";
  stickyHeader.value.style.display = useRow ? "flex" : "none";
  stickyHeader.value.style.top =
    toolbarHeight +
    // Add the position of the scroll parent (in case it happens to be a dialog)
    (scrollParent instanceof HTMLElement
      ? scrollParent.getBoundingClientRect().top
      : 0) +
    "px";
  stickyHeader.value.style.width = vue?.$el.clientWidth + "px";
};
onMounted(() => {
  // Setup sticky headers: Find the nearest scroll parent and stick the date headers to it.
  let scrollParent = vue?.$el as HTMLElement | Document;
  scrollParentRef.value = scrollParent;
  while (
    scrollParent instanceof HTMLElement &&
    // Stop when we find a vertically scrollable element
    !["auto", "scroll"].includes(getComputedStyle(scrollParent).overflowY)
  ) {
    const newParent = scrollParent.parentElement;
    if (!newParent) {
      scrollParent = document;
      break;
    }
    scrollParent = newParent;
  }

  // If we come up with the <html> root element, we need to attach to the doc instead.
  if (scrollParent == document.documentElement) scrollParent = document;

  scrollParent.addEventListener("scroll", () => listener(scrollParent));
});

onBeforeUnmount(() => {
  scrollParentRef.value?.removeEventListener("scroll", () =>
    listener(scrollParentRef.value as HTMLElement | Document),
  );
});

const eventDatesByDate = computed(() => {
  // Bucket the events by day.
  return props.viewModel.$items.reduce(
    (
      resultMap: { [key: string]: Array<EventDateDto> },
      eventDate: EventDateDto,
    ) => {
      const bucket = startOfDay(eventDate.eventStartDateTimeLocal!).toString();

      if (!Object.prototype.hasOwnProperty.call(resultMap, bucket)) {
        resultMap[bucket] = [];
      }

      resultMap[bucket].push(eventDate);
      return resultMap;
    },
    {} as { [key: string]: Array<EventDateDto> },
  );
});
</script>

<style lang="scss">
.left-align {
  float: left;
}

.list-image-container .event-date-image {
  display: block;
  position: relative;
  width: 100%;
  max-width: 150px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
  .event-dates-list table td[data-label].list-image-container {
    padding-left: 10px !important;
  }

  .list-image-container .event-date-image {
    display: block;
    position: relative;
    width: 100%;
    max-width: 450px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .event-dates-list table td[data-label].list-image-container {
    padding-left: 10px !important;
  }

  .list-image-container .event-date-image {
    display: block;
    position: relative;
    width: 100%;
    max-width: 320px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 300px) {
  .event-dates-list table td[data-label].list-image-container {
    padding-left: 10px !important;
  }

  .list-image-container .event-date-image {
    display: block;
    position: relative;
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.event-dates-list-container {
  .date-heading-row {
    background-color: #f5f5f5 !important;
    font-weight: 300 !important;
    border-color: rgba(0, 0, 0, 0.12) !important;

    &,
    > td {
      font-size: 24px !important;
    }
  }
}

.event-dates-list {
  table td.only-stacked,
  table tr.only-stacked {
    display: none;
  }

  $label-width: 90px;
  $padding: 4px;

  @media screen and (max-width: 1000px) {
    table {
      border: 0;

      td.only-normal,
      tr.only-normal {
        display: none;
      }

      td.only-stacked,
      tr.only-stacked {
        display: block;
      }

      thead {
        display: none;
      }

      tr {
        &.event-date-row {
          border-bottom: 6px solid #eee;
        }

        &:not(.event-date-row) {
          td {
            border-top: 1px solid #ddd;
          }
        }

        display: block;
      }

      td {
        display: block;
        text-align: left;
        font-size: 13px !important;

        position: relative;

        &[data-label] {
          padding-left: $label-width + 15px !important;
        }

        padding-top: $padding !important;
        padding-bottom: $padding !important;
        height: auto !important;
        min-height: 28px;
      }

      td:first-child {
        // border-top: 1px dotted #ccc;
      }

      td:last-child {
        border-bottom: none;
      }

      td:empty {
        display: none;
      }

      td:last-child {
        border-bottom: 0;
      }

      .actions-row {
        /* Adjust the apdding to compensate for the increased line height */
        padding-top: 2px;
        padding-bottom: 3px;
      }

      .actions-row > * {
        padding-right: 25px;
        white-space: nowrap;
        line-height: 250%;
      }

      tfoot tr {
        margin-bottom: 0px;
      }

      td[data-label]:before {
        content: attr(data-label);
        // text-transform: uppercase;
        font-weight: 300;

        position: absolute;
        left: 6px;
        width: $label-width;
        padding-right: 10px;
        white-space: nowrap;
      }
    }
  }
}
</style>
