<template>
  <div
    class="seat-map d-flex flex-no-wrap"
    :class="{ 'is-panning': !!context.currentPanAction }"
    @contextmenu.stop.prevent
  >
    <div class="flex-grow-1 d-flex flex-column" style="min-width: 0">
      <v-alert
        v-if="!context.allSeats.length"
        type="info"
        variant="outlined"
        class="ma-2"
      >
        There don't seem to be any available seats for this event.
        <span v-if="isPublicUser">Please check back later.</span>
        <span v-else> The event is probably misconfigured. </span>
      </v-alert>
      <div v-else class="seat-map-container">
        <div class="seat-map-controls" @mousedown.stop.prevent>
          <!-- <v-slider
            :max="context.zoomMax"
            :min="context.zoomMin"
            step="0.001"
            vertical
            v-model="context.zoom"
          ></v-slider> -->
          <v-sheet elevation="2" rounded max-width="45">
            <v-btn
              @click="context.zoomButton($event, 2)"
              color="primary"
              block
              variant="text"
              size="large"
              :disabled="context.zoom >= context.zoomMax"
            >
              <v-icon> fa fa-search-plus</v-icon>
            </v-btn>
            <v-btn
              class="my-1"
              @click="context.zoomButton($event, 1 / 2)"
              color="primary"
              block
              variant="text"
              size="large"
              :disabled="context.zoom <= context.zoomMin"
            >
              <v-icon> fa fa-search-minus</v-icon>
            </v-btn>
            <v-tooltip location="left">
              <template v-slot:activator="{ props }">
                <v-btn
                  block
                  v-bind="props"
                  size="large"
                  variant="text"
                  @click="printSeatMap"
                >
                  <v-icon size="20">fal fa-download</v-icon>
                </v-btn>
              </template>
              <span>Download Seat-map</span>
            </v-tooltip>
          </v-sheet>
        </div>

        <div
          class="seat-map-scroll-frame"
          @touchstart="context.onTouchStart($event)"
          @touchmove="context.onTouchMove($event)"
          @touchend="context.onTouchEnd($event)"
          @mousewheel="context.onMouseWheel($event)"
          @dblclick="context.onDoubleClick($event)"
        >
          <div
            class="seat-map-drawing-wrapper"
            :style="{
              // This intermediate wrapping div lets us compensate for the fact that
              // transform:scale() does not shrink the bounding box of an element.
              // What happens without this is that when zoomed < 1, the render and scroll
              // area does not get any shorter in the Y axis. By setting an explicit height
              // with Y overflow hidden, this extra whitespace is removed.
              maxHeight: Math.max(100, height * context.zoom) + 'px',
              height: Math.max(100, height * context.zoom) + 'px',
              width: Math.max(100, width * context.zoom) + 'px',
              overflow: 'hidden',
              '--zoom': context.zoom,
            }"
            :class="{
              'show-grid': context.showGrid,
            }"
          >
            <div
              id="seat-map-drawing"
              class="seat-map-drawing"
              :style="{
                width: width + 'px',
                minWidth: width + 'px',
                height: height + 'px',
                transform: context.drawingTransform,
              }"
              :class="{
                'hide-seat-text': context.hideSeatText,
              }"
              @mousedown="context.onDrawingMouseDown($event)"
            >
              <!-- DO NOT make seat-map-drawing have any slots.
              Doing so will cause it to always re-render when seat-map-render does,
              which kills performance during things like zooming. -->
              <seat-map-drawing
                :key="`SeatMap-${context.selectedObjects.size}`"
                :context="context"
              ></seat-map-drawing>

              <div
                class="seat-map-selection"
                v-if="context.selection"
                :style="{
                  top: context.selection.top + 'px',
                  left: context.selection.left + 'px',
                  width: Math.abs(context.selection.clampedWidth) + 'px',
                  height: Math.abs(context.selection.clampedHeight) + 'px',
                }"
              ></div>

              <!-- Instead, we put the slot for "other drawables" here. -->
              <slot name="drawing-append" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.seat-map {
  // border: 1px solid black;

  &.can-pan {
    .seat-map-drawing {
      cursor: grab !important;
    }
  }

  &.is-panning {
    .seat-map-drawing {
      cursor: grabbing !important;
    }
  }

  .seat-map-selection {
    border: 2px dashed #333;
    position: absolute;
    user-select: none;
    pointer-events: none;
  }

  .seat-map-container {
    flex-grow: 1;
    overflow: auto;
    position: relative;

    .seat-map-scroll-frame {
      overflow: auto;
      max-height: 70vh;
      padding: 12px 24px 12px 54px;
    }

    .seat-map-drawing-wrapper {
      &.show-grid {
        /** A function that return 0 if $value <= $target, or 1 if $value > $target. */
        @function isGreaterThan($value, $target) {
          // The multiplier is so that this works on small fractional numbers.
          // Without this, the two numbers need to have a distance of 1 for this to work
          // (distances between 0 and 1 between the numbers would yield that distance as the return value).
          // With this, the two numbers need to have a distance of 1/$mult to get a definitive
          // 0 or 1 return (distances smaller than that will return the distance).
          $mult: 10000;

          @return Min(1, Max((#{$value} * #{$mult}) - #{$target * $mult}, 0));
        }

        $intervals: // Every 5px
          5px rgba(black, calc(0.07 * #{isGreaterThan(var(--zoom), 1.65)})),
          // Every 15px
          15px rgba(black, calc(0.12 * #{isGreaterThan(var(--zoom), 0.5)})),
          // Every 30px
          30px rgba(black, 0.2);

        $bgImage: ();
        $bgSize: ();
        @each $size, $color in $intervals {
          $bgImage: append(
            $bgImage,
            (
              linear-gradient(to right, $color 1px, transparent 1px),
              linear-gradient(to bottom, $color 1px, transparent 1px)
            ),
            $separator: comma
          );

          $bgSize: append(
            $bgSize,
            (
              calc(var(--zoom) * #{$size}) calc(var(--zoom) * #{$size}),
              calc(var(--zoom) * #{$size}) calc(var(--zoom) * #{$size})
            ),
            $separator: comma
          );
        }

        background-repeat: repeat;
        background-position: top -0.5px left -0.5px;
        background-image: $bgImage;
        background-size: $bgSize;
      }
    }
  }

  .seat-map-controls {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 7; // Default vuetify dropdown z index is 8. This needs to be lower than that.
  }
}
</style>

<script setup lang="ts">
import {
  computed,
  getCurrentInstance,
  watch,
  onMounted,
  onBeforeUnmount,
} from "vue";
import useStaticData from "@common/composables/useStaticData";
import { SeatMapContext } from "@common/seat-map-context";
import SeatMapDrawing from "./seat-map-drawing.vue";
// @ts-ignore
import html2pdf from "html2pdf.js";

const isPublicUser = useStaticData().isPublicUser;
const props = defineProps<{
  context: SeatMapContext;
}>();

const vueProxy = getCurrentInstance()?.proxy;

const seatMap = computed(() => {
  return props.context.seatMap;
});

const width = computed(() => {
  return (
    Math.max(
      ...props.context.allSeats.map((seat) => seat.x! + props.context.seatSize),
      ...seatMap.value.annotations!.map(
        (annotation) => annotation.x! + annotation.width!,
      ),
      //  (this.selection?.x ?? 0) + (this.selection?.width ?? 0)
    ) + props.context.bottomRightBuffer
  );
});

const height = computed(() => {
  return (
    Math.max(
      ...props.context.allSeats.map((seat) => seat.y! + props.context.seatSize),
      ...seatMap.value.annotations!.map(
        (annotation) => annotation.y! + annotation.height!,
      ),
      // (this.selection?.y ?? 0) + (this.selection?.height ?? 0)
    ) + props.context.bottomRightBuffer
  );
});

const hasAutoZoomed = ref(false); // Tracks if auto-zoom has already been applied


// Set the minimum zoom such that the entire thing fits in its viewport.
const adjustMinZoom = () => {

  if (hasAutoZoomed.value) {
    props.context.zoomMin = +Math.min(
      0.005,
      // the amount subtracted from clientWidth represents the padding on the outer wrapper
      (window.innerWidth! - 55) / width.value,
    ).toFixed(4);
  } else {
    const container = vueProxy?.$el?.querySelector(".seat-map-container");
    if (!container) return;

    // Get container dimensions
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    // Compute best zoom to fit the seat map
    const scaleFactor = Math.min(
      containerWidth / width.value,
      containerHeight / height.value
    );

    // Ensure zoom is within valid bounds
    const autoZoom = Math.min(Math.max(scaleFactor, props.context.zoomMin), props.context.zoomMax);

    props.context.zoom = autoZoom; // Set initial zoom
    hasAutoZoomed.value = true; // Mark that auto-zoom has been applied
  }
};

const observer = new ResizeObserver(adjustMinZoom);

onMounted(() => {
  observer.observe(vueProxy?.$el!);
});

onBeforeUnmount(() => observer.disconnect());

watch(() => window.innerWidth, adjustMinZoom);

function printSeatMap() {
  const element = document.getElementById("seat-map-drawing");
  const opt = {
    margin: 0.125,
    filename: "seat-map.pdf",
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: {
      unit: "in",
      format: "a3",
      orientation: "landscape",
      "content-width": "1400px",
    },
  };
  new html2pdf().set(opt).from(element).save();
}
</script>
