<template>
  <div>
    <div style="font-size: 20px">
      <strong>
        <c-display
          :model="eventDate"
          for="eventStartDateTimeLocal"
          format="EEEE, MMMM do, yyyy"
        />
      </strong>
      &mdash;
      <span style="white-space: nowrap">
        <component :is="eventDate.eventBoardingMinutes ? 'span' : 'strong'">
          <c-display
            :model="eventDate"
            for="eventStartDateTimeLocal"
            format="h:mm a"
          />
        </component>
        -
        <c-display
          :model="eventDate"
          for="eventEndDateTimeLocal"
          format="h:mm a"
        />
        (<c-display :model="eventDate" for="venueTimezone" />).
      </span>
    </div>
    <div class="bosdy-1 pt-1">
      <span>
        <template
          v-if="
            eventDate.eventBoardingMinutes && eventDate.eventBoardingMinutes > 0
          "
        >
          <c-display :model="eventDate" for="eventBoardingLabel" />
          <span>&nbsp;</span>
          <strong>{{ boardingTimeFormatted }}</strong>
          <template v-if="eventDate.eventLocation">
            at
            <strong>
              <c-display :model="eventDate" for="eventLocation" />
            </strong>
          </template>
        </template>
        <template v-else-if="eventDate.eventLocation">
          Location:
          <c-display :model="eventDate" for="eventLocation" /> </template
        >.</span
      >
    </div>
    <template v-if="eventDate.eventLongDescription">
      <v-divider class="my-2"></v-divider>

      <span
        class="text-pre-wrap"
        v-html="sanitizeHtml(eventDate.eventLongDescription.trim())"
      ></span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { EventDateDto } from "@/models.g";
import { format, subMinutes } from "date-fns";
import { computed } from "vue";
import { sanitizeHtml } from "@common/utils";

const props = defineProps<{ eventDate: EventDateDto }>();
const boardingTimeFormatted = computed(() => {
  return format(
    subMinutes(
      props.eventDate.eventStartDateTimeLocal!,
      props.eventDate.eventBoardingMinutes!,
    ),
    "h:mm a",
  );
});
</script>
