import {
  ViewModel,
  Model,
  ModelType,
  ItemApiState,
  convertToModel,
} from "coalesce-vue";
import {
  TicketPurchaseTicket,
  EventDateDto,
  TicketPurchaseAddon,
} from "@/models.g";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatCurrency(amount: number | null) {
  return currencyFormatter.format(amount ?? 0);
}

export function isTouch() {
  return "ontouchstart" in window || (navigator.maxTouchPoints ?? 0) > 0;
}

export function formatMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60),
    minutes = totalMinutes % 60;
  let result = "";

  if (hours == 1) {
    result = "1 hour ";
  } else if (hours > 1) {
    result = hours + " hours ";
  }
  if (minutes > 0) {
    result += minutes + " minutes";
  }
  return result.trim();
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/** Reload `target` after a very slight delay if `target` is not saving,
 * `responseSource` is not pending,
 * and nothing in `otherPendingLoaders` is pending. */
export function reloadIfNoRequestsPending<TModel extends Model<ModelType>>(
  target: ViewModel<TModel>,
  responseSource: ItemApiState<any, TModel>,
  ...otherPendingLoaders: ItemApiState<any, any>[]
) {
  // Delay slightly to allow pending debounced requests to kick off.
  setTimeout(() => {
    if (
      responseSource.result &&
      !target.$save.isLoading &&
      (!otherPendingLoaders || !otherPendingLoaders.some((l) => l.isLoading))
    ) {
      // Temp workaround for a coalesce-vue bug that isn't converting "object"s
      // to Model implementations when setting them on a ViewModel.
      // This is an issue if we're loading the result from a failed request,
      // which doesn't automatically convert the response into a Model like successes do.
      convertToModel(responseSource.result, target.$metadata);

      target.$loadCleanData(responseSource.result!);
    }
  }, 10);
}

export function setupPurchaseReloadHandling<TModel extends Model<ModelType>>(
  ticketPurchase: ViewModel<TModel>,
  responseSource: ItemApiState<any, TModel>,
  ...otherPendingLoaders: ItemApiState<any, any>[]
) {
  const cb = () =>
    reloadIfNoRequestsPending(
      ticketPurchase,
      responseSource,
      ...otherPendingLoaders,
    );

  responseSource.onFulfilled(cb);
  responseSource.onRejected(cb);
}

export function scrollToTop(
  wait: number = 200,
  behavior: ScrollBehavior = "smooth",
) {
  setTimeout(
    () => window.scrollTo({ left: 0, top: 0, behavior: behavior }),
    wait,
  );
}

export function getTicketGroupings(
  tickets: TicketPurchaseTicket[],
  eventDate?: EventDateDto,
) {
  const groupings: {
    [key: string]: {
      firstTicket: TicketPurchaseTicket;
      allTickets: TicketPurchaseTicket[];
    };
  } = {};

  for (const ticket of tickets) {
    const groupingKey = [
      ticket.price,
      ticket.fees,
      ticket.subtotalWithFees,
      ticket.discountTotal,
      ticket.addons,
      ticket.salesTax,
      ticket.convenienceFee,
      ticket.totalValue,
      ticket.relatedPurchase?.publicId,
      ticket.relatedPurchase?.description,
      ticket.description,
      ticket.status,
    ];
    const groupingString = JSON.stringify(groupingKey);

    let grouping = groupings[groupingString];
    if (!grouping) {
      grouping = groupings[groupingString] = {
        firstTicket: ticket,
        allTickets: [],
      };
    }
    grouping.allTickets.push(ticket);
  }

  function indexOf(s: string | null) {
    return eventDate!.eventPrices!.findIndex((p) =>
      s!.startsWith(p.displayName!),
    );
  }

  // Sort the results by the position of the ticket in the
  // list of event prices for the event date.
  // This keeps the ordering consistent when picking quantities + any summary views.
  return Object.values(groupings).sort((a, b) => {
    if (eventDate) {
      return (
        indexOf(a.firstTicket.description) - indexOf(b.firstTicket.description)
      );
    }

    return a.firstTicket.description!.localeCompare(b.firstTicket.description!);
  });
}

export function getAddonGroupings(addons: TicketPurchaseAddon[]) {
  const groupings: {
    [key: string]: {
      firstAddon: TicketPurchaseAddon;
      allAddons: TicketPurchaseAddon[];
    };
  } = {};

  for (const addon of addons) {
    const groupingKey = [
      addon.name,
      addon.price,
      addon.salesTax,
      addon.convenienceFee,
      addon.refundedPrice,
      addon.relatedPurchase?.publicId,
      addon.relatedPurchase?.description,
      addon.marketingDescription,
      addon.status,
    ];
    const groupingString = JSON.stringify(groupingKey);

    let grouping = groupings[groupingString];
    if (!grouping) {
      grouping = groupings[groupingString] = {
        firstAddon: addon,
        allAddons: [],
      };
    }
    grouping.allAddons.push(addon);
  }

  return Object.values(groupings).sort((a, b) => {
    return a.firstAddon.name!.localeCompare(b.firstAddon.name!);
  });
}
import DOMPurify from "dompurify";

export function sanitizeHtml(html: string): string {
  const sanitized = DOMPurify.sanitize(html)
  return sanitized;
}

import { format, parse, isValid } from "date-fns";
export class EventDatesParameters {
  public tab: "list" | "calendar" = "list";
  public calendarType: "month" | "day" = "month";

  public get mode(): "calendar-day" | "calendar-month" | "list" {
    return (this.tab +
      (this.tab == "calendar" ? "-" + this.calendarType : "")) as any;
  }
  public set mode(val) {
    const parts = val.split("-");
    this.tab = parts[0] as any;
    this.calendarType = parts[1] || (this.calendarType as any);
  }

  public calendarDate = new Date();
  public get calendarDateString() {
    return format(this.calendarDate, "yyyy-MM-dd");
  }
  public set calendarDateString(val) {
    const newDate = parse(val, "yyyy-MM-dd", this.calendarDate);
    if (isValid(newDate)) {
      this.calendarDate = newDate;
    }
  }

  public filtersExpanded = false;
  public eventTypeId: number | null = null;
  public eventId: number | null = null;
  public onlyPast: boolean = false;
  public availableSeatFilter: number | null = null;

  public get hasFilters() {
    return !!this.eventTypeId || !!this.eventId || this.onlyPast;
  }
}
