<template>
  <div class="purchase-quantity-selector">
    <div v-if="purchase.eventDate?.isSeated" class="text-caption font-italic">
      <template v-if="isTouch()">Pinch to zoom.</template>
      <template v-else>
        Use the buttons to zoom. Click-and-drag to move around.
      </template>
      Tap on a seat to view pricing and add it to your purchase.
    </div>

    <v-card variant="outlined">
      <!-- Seated Event: Show Seat Map -->
      <div v-if="purchase?.eventDate?.isSeated">
        <purchase-seat-map :purchase="purchase" />
      </div>

      <!-- Unseated Event: Show Table or Mobile Cards -->
      <template v-else>
        <!-- Desktop: Table layout -->
        <v-table v-if="display.mdAndUp.value" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Item</th>
                <th class="text-center">Quantity</th>
                <th class="text-right">Price</th>
                <th class="text-right">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <purchase-quantity-selector-row
                v-for="eventPrice in purchase?.eventDate?.eventPrices"
                :purchase="purchase"
                :eventPrice="eventPrice"
                :key="eventPrice.id + '-price'"
              />
              <!-- Table Footer -->
              <tr>
                <td>
                  <v-fade-transition>
                    <div
                      class="text-h6 text-info py-3"
                      v-if="purchase?.payments?.length"
                    >
                      <span class="pr-2">Balance:</span>
                      <span
                        :class="{
                          'error--text': (purchase?.balance ?? -1) < 0,
                        }"
                      >
                        {{ formatCurrency(purchase.balance) }}
                      </span>
                    </div>
                  </v-fade-transition>
                </td>
                <td :colspan="display.mdAndUp.value ? 3 : 2">
                  <div class="text-h6 text-right py-3">
                    <span class="pr-2">Subtotal:</span>
                    {{ formatCurrency(totalPrice) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>

        <!-- Mobile: Card Layout -->
        <div v-else class="mobile-ticket-list">
          <purchase-quantity-selector-row
            v-for="eventPrice in purchase?.eventDate?.eventPrices"
            :purchase="purchase"
            :eventPrice="eventPrice"
            :key="eventPrice.id + '-price'"
            class="px-0 py-0"
          />
          <v-divider class="mt-2"></v-divider>

          <!-- Total at Bottom -->
          <v-row class="border-0">
            <v-col class="text-right">
              <div class="total-container text-h6 pa-2">
                <span class="pr-2 font-weight-bold">Subtotal:</span>
                {{ formatCurrency(totalPrice) }}
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useDisplay } from "vuetify";
import { formatCurrency, isTouch } from "@common/utils";
import { TicketPurchaseDtoViewModel } from "@/viewmodels.g";
import PurchaseQuantitySelectorRow from "./purchase-quantity-selector-row.vue";
import PurchaseSeatMap from "./purchase-seat-map.vue";

const display = useDisplay();
const props = defineProps<{ purchase: TicketPurchaseDtoViewModel }>();

const totalPrice = computed(() => {
  return (props.purchase.tickets || []).reduce((acc, ticketQuantity) => {
    return acc + (ticketQuantity.subtotalWithFees ?? 0);
  }, 0);
});
</script>
