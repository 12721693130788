import { createVuetify } from "vuetify";
import { createCoalesceVuetify } from "coalesce-vue-vuetify3";
import { aliases, fa } from "vuetify/iconsets/fa";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { App } from "vue";
import { EmailEditor } from "vue-email-editor";

import LabeledSection from "./components/labeled-section.vue";
import QuantityInput from "./components/quantity-input.vue";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// Import global CSS and Fonts:
import "typeface-roboto";
import "@fortawesome/fontawesome-pro/css/all.css";
import "coalesce-vue-vuetify3/styles.css";
import "@common/shared.scss";
import "vuetify/styles";
import FullCalendar from "@fullcalendar/vue3";

import $metadata from "@/metadata.g";
// // viewmodels.g has sideeffects - it populates the global lookup on ViewModel and ListViewModel.
import "@/viewmodels.g";
import useAppInsights from "@common/composables/useAppInsights";

// For Google Analytics/GTM/etc.
declare global {
  interface Window {
    appInsights?: ApplicationInsights;
  }
}
window.dataLayer = window.dataLayer || [];

export interface TicketingCommonPluginOptions {
  publicUser: boolean;
}

const staticData = useStaticData();

export default function TicketingCommonPlugin(
  app: App<Element>,
  options: TicketingCommonPluginOptions,
): void {
  if (!app)
    throw new Error(
      "Must pass your app instance to configure the common plugin",
    );

  // Truncate plugin
  const truncate = function (
    text: string,
    length: number | undefined,
    clamp: string,
  ) {
    clamp = clamp || "...";
    const node = document.createElement("div");
    node.innerHTML = text;
    const content = node.textContent;
    return content!.length > length!
      ? content!.slice(0, length) + clamp
      : content;
  };
  app.config.globalProperties.$filters = {
    truncate,
  };

  window.dataLayer?.push({
    isPublicUser: options.publicUser,
    userId: staticData.USERNAME || null,
  });

  const appInsights = useAppInsights();
  appInsights.trackPageView();

  // Shared, globally registered components.
  app.component("labeled-section", LabeledSection);
  app.component("quantity-input", QuantityInput);
}

declare module "vue" {
  interface EmailTemplateEditor {
    editor: {
      loadDesign: (data: any) => void;
      saveDesign: (data: any) => void;
      exportHtml: (data: any) => void;
    };
  }
}

export const coalesceVuetify = createCoalesceVuetify({
  metadata: $metadata,
});

const inputDefaults = { density: "compact", variant: "outlined" };
export const VuetifyOptions = {
  icons: {
    defaultSet: "fa",
    aliases,
    sets: { fa },
  },
  defaults: {
    VInput: inputDefaults,
    VAutocomplete: inputDefaults,
    VField: inputDefaults,
    VTextarea: inputDefaults,
    VSelect: inputDefaults,
    VTextField: {
      density: "compact",
      variant: "outlined",
      hideDetails: "auto",
    },
    VSwitch: { baseColor: "disabled", color: "primary" },
  },
  components: {
    FullCalendar,
    EmailEditor,
    QuillEditor
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: staticData.COLOR_PRIMARY,
          secondary: staticData.COLOR_SECONDARY,
          accent: staticData.COLOR_ACCENT,
          "surface-variant": "#bbb",

          error: "#df323b", // This is the default error color with darken-1
        },
      },
    },
  },
};

export const vuetify = createVuetify(VuetifyOptions);
