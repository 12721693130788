<template>
  <!-- Desktop Row -->
  <tr class="purchase-quantity-selector-row" v-if="!isMobile">
    <td class="pt-4 pb-3" style="vertical-align: top">
      <span class="text-h6">{{ eventPrice.description }}</span>
      <div class="text-caption pt-1">
        {{ formatCurrency(eventPrice.price) }} +
        <span class="text-no-wrap">
          {{ formatCurrency(eventPrice.serviceFee) }} service fee
        </span>
      </div>
    </td>
    <td class="text-center py-4">
      <div class="quantity-wrapper">
        <quantity-input
          :value="localQuantity"
          @update="setQuantity"
          :loading="service.setTicketQuantity.isLoading"
          :max="maxTickets"
          :hint="`Max Purchase Qty: ${maxTickets}`"
          :hideDetails="false"
        />
      </div>
    </td>
    <td class="ticket-price-total-cell text-right">
      <v-fade-transition mode="out-in">
        <v-skeleton-loader
          v-if="service.setTicketQuantity.isLoading"
          type="text"
        />
        <div v-else>{{ subtotal }}</div>
      </v-fade-transition>
    </td>
    <td class="ticket-price-total-cell text-right">
      <v-fade-transition mode="out-in">
        <v-skeleton-loader
          v-if="service.setTicketQuantity.isLoading"
          type="text"
        />
        <div v-else>{{ subtotalWithFees }}</div>
      </v-fade-transition>
    </td>
  </tr>

  <!-- Mobile Row -->
  <v-card class="pa-3 pb-0 ma-4 quantity-card" variant="flat" v-else>
    <div class="text-h6">{{ eventPrice.description }}</div>
    <div class="text-caption">
      {{ formatCurrency(eventPrice.price) }} +
      <span class="text-no-wrap">
        {{ formatCurrency(eventPrice.serviceFee) }} service fee
      </span>
    </div>
    <div class="quantity-row">
      <span class="quantity-label text-h6">Quantity</span>
      <quantity-input
        :value="localQuantity"
        @update="setQuantity"
        :loading="service.setTicketQuantity.isLoading"
      />
    </div>
    <div class="subtotal-row">
      <span class="quantity-label text-h6">Subtotal w/ fees</span>
      <v-fade-transition mode="out-in">
        <v-skeleton-loader
          v-if="service.setTicketQuantity.isLoading"
          type="text"
        />
        <div v-else class="subtotal-value text-h6">{{ subtotalWithFees }}</div>
      </v-fade-transition>
    </div>
  </v-card>
</template>

<style lang="scss">
@media screen and (max-width: 960px) {
  .quantity-row,
  .subtotal-row {
    display: flex;
    justify-content: space-between; /* Ensures left label, right input/value */
    align-items: center;
    width: 100%;
    margin-top: 12px;
    white-space: nowrap; /* Prevents wrapping to next line */
  }

  .quantity-label {
    text-align: left;
    flex: 1; /* Ensures it takes up half the row */
  }

  .quantity-input,
  .subtotal-value {
    text-align: right;
    flex: 1; /* Ensures it takes up half the row */
    display: flex;
    justify-content: flex-end;
  }
}
</style>

<script setup lang="ts">
import { EventPriceDto } from "@/models.g";
import {
  PublicPurchaseServiceViewModel,
  TicketPurchaseDtoViewModel,
} from "@/viewmodels.g";
import { useDisplay } from "vuetify";
import { cartQuantityChanged } from "@common/analytics";
import { formatCurrency, setupPurchaseReloadHandling } from "@common/utils";
import { computed, ref } from "vue";

const display = useDisplay();
const isMobile = computed(() => display.smAndDown.value);
const props = defineProps<{
  purchase: TicketPurchaseDtoViewModel;
  eventPrice: EventPriceDto;
}>();
const service = new PublicPurchaseServiceViewModel();

const localQuantity = ref(0);

function refreshLocalQuantity() {
  localQuantity.value = tickets.value.length;
}

const tickets = computed(() => {
  return props.purchase.tickets!.filter(
    (t) => t.eventPriceId == props.eventPrice.id,
  );
});

// This is done on purpose as we don't want the max to change while we increase the value
// eslint-disable-next-line vue/no-setup-props-destructure
const initAvailableSeats = props.purchase.eventDate?.availableSeats;

const maxTickets = computed(() => {
  return props.eventPrice.maximumPurchaseQuantity != 0
    ? props.eventPrice.maximumPurchaseQuantity!
    : initAvailableSeats || 9999;
});

const subtotal = computed(() => {
  return formatCurrency(
    tickets.value.reduce((prev, cur) => prev + cur.price!, 0),
  );
});

const subtotalWithFees = computed(() => {
  return formatCurrency(
    tickets.value.reduce((prev, cur) => prev + cur.subtotalWithFees!, 0),
  );
});

async function setQuantity(input: string | number) {
  var num = parseInt(input.toString());
  const oldLocalQuantity = localQuantity.value;
  var minimumQuantity = props.eventPrice.minimumQuantity ?? 0;
  var maximumPurchaseQuantity = props.eventPrice.maximumPurchaseQuantity!;

  // If we're an agent, we probably want to skip over some of these checks?

  if (isNaN(num)) {
    num = 0;
  } else if (num < minimumQuantity) {
    // If the users decrements below the minimum quantity, set to zero.
    // If the user increments but are below the minimum quantity, set it to the minimum quantity.
    num = num < oldLocalQuantity ? 0 : minimumQuantity;
  } else if (maximumPurchaseQuantity && num > maximumPurchaseQuantity) {
    num = maximumPurchaseQuantity;
  }
  localQuantity.value = num;

  try {
    await service.setTicketQuantity(
      props.purchase.publicId,
      props.eventPrice.id,
      num,
    );

    cartQuantityChanged(
      props.purchase,
      props.eventPrice,
      num - oldLocalQuantity,
    );
  } catch (err) {
    console.error(err);

    // Always refresh the local quantities if there is an error.
    refreshLocalQuantity();
  }
}

service.setTicketQuantity.setConcurrency("debounce");
setupPurchaseReloadHandling(props.purchase, service.setTicketQuantity);

refreshLocalQuantity();
</script>
